.fc-toast-container {
    width: 100%;
    max-width: 500px;
}

.fc-toast {
    font-family: "Be Vietnam", sans-serif;
    border: 2px solid var(--light-grey);
    box-shadow: none;
    color: var(--black);
    line-height: 1.2;
    width: auto;
    align-items: center;
    padding: 0 var(--size-08) 0 0;
    min-height: var(--size-48);
    font-size: 12px;

    &:before {
        display: inline-block;
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.error {
        border-color: var(--red);

        &:before {
            background-color: var(--red);
            content: url("../../assets/images/icons/error.svg");
        }
    }

    &.success {
        border-color: var(--green);

        &:before {
            background-color: var(--green);
            content: url("../../assets/images/icons/success.svg");
        }
    }

    &.info {
        border-color: var(--orange);

        &:before {
            background-color: var(--orange);
            content: url("../../assets/images/icons/info.svg");
        }
    }

    .Toastify__close-button--default {
        opacity: 1;
        color: var(--black);
        align-self: inherit;
    }
}

.fc-toast-body {
    padding: 0 48px 0 16px;
}
