@import "./base/fonts";
@import "./vendor/bootstrap";
@import "./vendor/toastify";

:root {
    --black: #212529;
    --white: #ffffff;
    --primary: #00718f;
    --secondary: #e5f0f3;
    --tertiary: #ed706c;
    --grey: #b3b3b3;
    --light-grey: #f3f2f2;
    --purple: #4b4baf;
    --blue: #64c8fa;
    --orange: #f09632;
    --yellow: #f0c84b;
    --red: #f07d7d;
    --green: #9bf07d;
    --size-04: 4px;
    --size-08: 8px;
    --size-16: 16px;
    --size-24: 24px;
    --size-32: 32px;
    --size-48: 48px;
    --size-64: 64px;
    --size-80: 80px;
    --size-120: 120px;
    --size-240: 240px;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html,
body {
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    hyphens: auto;
    font-size: var(--size-24);
    line-height: 1.3;
    background-color: var(--secondary);
    color: var(--black);
}

a {
    text-decoration: none;
    color: var(--tertiary);

    &:hover,
    &:focus,
    &:active {
        color: darken(#ed706c, 0.1);
    }
}

h1 {
    font-size: 40px;
    line-height: 1.5;
    margin-bottom: 24px;
    font-weight: 500;
}

h2 {
    margin-bottom: 32px;
    font-size: 40px;
    font-weight: 500;
}

h3 {
    font-size: 32px;
    margin-bottom: 32px;
    font-weight: 700;
}

h4 {
    font-size: 24px;
    margin-bottom: 8px;
    font-weight: 500;
}

p {
    margin-bottom: 0.9rem;

    &.highlight {
        font-size: 24px;
    }
}
